import React from 'react';
import './App.css';
import * as Constants from "./Constants";
import axios from 'axios';
import classNames from 'classnames';
import ReactDOM from 'react-dom';


class WordpressMobile extends React.Component {
    state = {
        isDroppedDown: false
    };

    constructor(props) {
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    toggleDropdown(e) {
        e.preventDefault();
        this.setState({
            isDroppedDown: !this.state.isDroppedDown
        });
    }

    render() {
        let dropdownClass = classNames({
            'dropdown': true,
            'dropdown--is-visible': this.state.isDroppedDown
        });
        return (
            <ul className="mobile-apps-list">
                {(typeof (this.props.school) !== 'undefined' && this.props.school.sc_wordpress_url !== '') &&
                <li className="main-apps__app-icon">
                    <a href={this.props.school.sc_wordpress_url + "/wp-admin"} target="_blank"
                       rel="noopener noreferrer"
                       alt="app" className="">
                        <img alt="Wordpress Dashboard Icon"
                             src="https://cdn.realsmart.co.uk/new-cpanel/wordpress_icon.png"
                             className="app-icon app-icon-gmail"/>
                        <span>Wordpress Dashboard </span>
                    </a>
                </li>
                }
            </ul>
        );
    }
}

class WordpressDropdown extends React.Component {
    state = {
        isDroppedDown: false
    };

    constructor(props) {
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    toggleDropdown(e) {
        e.preventDefault();
        this.setState({
            isDroppedDown: !this.state.isDroppedDown
        });
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = e => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(e.target)) {
            this.setState({
                isDroppedDown: false
            });
        }
    }

    render() {
        let dropdownClass = classNames({
            'dropdown': true,
            'dropdown--is-visible': this.state.isDroppedDown
        });
        return (
            <div ref={node => this.node = node}>
                <li className="main-apps__app-icon">
                    <button target="_blank" rel="noopener noreferrer" alt="app" onClick={this.toggleDropdown}
                            className="app-icon app-icon-wordpress">
                        <img alt="wordpress icon" src="https://cdn.realsmart.co.uk/new-cpanel/wordpress_icon.png"
                             className="app-icon app-icon-wordpress"/>
                    </button>
                    {(typeof (this.props.school) !== 'undefined' && this.props.school.sc_wordpress_url !== '') &&
                    <ul id="wordpress-dropdown" className={dropdownClass}>
                        <li className="wordpress-dropdown--item">
                            <a href={this.props.school.sc_wordpress_url + "/wp-admin"} target="_blank"
                               rel="noopener noreferrer"
                               alt="app" className="">
                                <img alt="wordpress dashboard"
                                     src="https://cdn.realsmart.co.uk/new-cpanel/wordpress_icon.png"
                                     className="app-icon app-icon-gmail"/>
                                <span>Wordpress Dashboard</span>
                            </a>
                        </li>
                        <li className="wordpress-dropdown--item">
                            <a href={this.props.school.sc_wordpress_url + "/wp-admin/post-new.php"}
                               target="_blank" rel="noopener noreferrer" alt="app" className="">
                                <img alt="wordpress add a post"
                                     src="https://cdn.realsmart.co.uk/new-cpanel/wordpress_icon.png"
                                     className="app-icon app-icon-gmail"/>
                                <span>Add a post</span>
                            </a>
                        </li>
                        <li className="wordpress-dropdown--item">
                            <a href={this.props.school.sc_wordpress_url + "/wp-admin/post-new.php?post_type=page"}
                               target="_blank" rel="noopener noreferrer" alt="app" className="">
                                <img alt="wordpress add a page"
                                     src="https://cdn.realsmart.co.uk/new-cpanel/wordpress_icon.png"
                                     className="app-icon app-icon-gmail"/>
                                <span>Add a page</span>
                            </a>
                        </li>
                    </ul>
                    }
                </li>
            </div>
        );
    }
}

class TimetableLesson extends React.Component {
    state = {
        isDroppedDown: false
    };

    constructor(props) {
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    toggleDropdown(e) {
        e.preventDefault();
        this.setState({
            isDroppedDown: !this.state.isDroppedDown
        });
    }

    render() {
        return (
            <div>
                {this.props.lessons.map((lesson, key) =>
                    <div className="lesson--container" key={key}>
                        <div class="lesson--div">

                            <div className="lesson--class-name">
                                <strong>{lesson.class_name}</strong>
                            </div>
                            <div className="lesson--period-number"><strong>Period</strong> {lesson.period_name}</div>
                            {(typeof (lesson.start_at.date) !== 'undefined' && lesson.start_at.date != 'na') &&
                            <div className="lesson--start">
                                <strong>Start:</strong> {lesson.start_at.date.substring(10, 16)}
                            </div>
                            }
                            {(lesson.employee_name != 'na') &&
                            <div className="lesson--employee-name">
                                {lesson.employee_name}
                            </div>
                            }
                            <div className="lesson--room-name">
                                <strong>Location:</strong> {lesson.room_name}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

class TimetableDropdown extends React.Component {
    state = {
        isDroppedDown: false
    };

    constructor(props) {
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    toggleDropdown(e) {
        e.preventDefault();
        this.setState({
            isDroppedDown: !this.state.isDroppedDown
        });
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = e => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(e.target)) {
            this.setState({
                isDroppedDown: false
            });
        }
    }

    render() {
        let dropdownClass = classNames({
            'dropdown': true,
            'dropdown--is-visible': this.state.isDroppedDown
        });
        return (
            <li className="other-apps__app-icon app-icon--apps timetable-dropdown">
                <button target="_blank" rel="noopener noreferrer" alt="app" onClick={this.toggleDropdown}
                        className="apps-links has-dropdown cpanel-tooltip">
                    <img alt="Timetable Dropdown Icon"
                         src="https://cdn.realsmart.co.uk/new-cpanel/cpanel-timetable-icon.svg"
                         className="app-icon app-icon-wordpress"/>
                    <span className="tooltiptext">Timetable</span>
                </button>
                <ul id="apps__dropdown" className={dropdownClass}>
                    {this.props.timetableItems.map((timetable, timetableKey) =>
                        <div className="timetable-day" key={timetableKey}>
                            <h3><span>{timetable[0]['day_name']}</span></h3>
                            <TimetableLesson lessons={timetable[0]['processed_lessons']}/>
                        </div>
                    )}
                </ul>
            </li>

        );
    }
}


class AppDropdown extends React.Component {
    state = {
        isDroppedDown: false
    };

    constructor(props) {
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    toggleDropdown(e) {
        e.preventDefault();
        this.setState({
            isDroppedDown: !this.state.isDroppedDown
        });
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = e => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(e.target)) {
            this.setState({
                isDroppedDown: false
            });
        }
    }

    render() {
        let dropdownClass = classNames({
            'dropdown': true,
            'dropdown--is-visible': this.state.isDroppedDown
        });
        return (
            <li className="other-apps__app-icon app-icon--apps apps-dropdown">
                <button target="_blank" rel="noopener noreferrer" alt="app" onClick={this.toggleDropdown}
                        className="apps-links has-dropdown cpanel-tooltip">
                    <img alt="All apps dropdown Icon" src="https://cdn.realsmart.co.uk/new-cpanel/cpanel-apps-icon.svg"
                         className="app-icon app-icon-wordpress"/>
                    <span className="tooltiptext">Other apps</span>
                </button>
                <ul id="apps__dropdown" className={dropdownClass}>
                    {(this.props.starredApps.length === 0 && this.props.apps.length === 0) &&
                    <div className="apps-label"><span className="apps-label__title">No Apps Enabled.</span></div>
                    }
                    {this.props.starredApps.length > 0 &&
                    <div>
                        <div className="apps-label"><span className="apps-label__title">Starred Apps</span></div>
                        {this.props.starredApps.map((app, i) =>
                            <li className="apps__dropdown--item" key={i}>
                                <a href={app.link} target="_blank" rel="noopener noreferrer" alt="app" className="">
                                    <img alt={app.title} src={app.image}
                                         className="app-icon app-icon-gmail"/>
                                    <span>{app.title}</span>
                                    {(app.badge != 0) && <span className="mail-badge">{app.badge}</span>}
                                </a>
                            </li>
                        )}
                    </div>
                    }
                    {this.props.apps.length > 0 &&
                    <div>
                        <div className="apps-label apps-label--margin-top"><span
                            className="apps-label__title">Other Apps</span></div>
                        {this.props.apps.map((app, i) =>
                            <li className="apps__dropdown--item" key={i}>
                                <a href={app.link} target="_blank" rel="noopener noreferrer" alt="app" className="">
                                    <img alt={app.title} src={app.image}
                                         className="app-icon app-icon-gmail"/>
                                    <span>{app.title}</span>
                                    {(app.badge != 0) && <span className="mail-badge">{app.badge}</span>}
                                </a>
                            </li>
                        )}
                    </div>
                    }
                </ul>
            </li>
        );
    }
}


class App extends React.Component {
    token = document.getElementById('root').dataset.token;
    loginButton = document.getElementById('root').dataset.loginButton;
    schoolId = document.getElementById('root').dataset.school;
    state = {
        starredApps: [],
        apps: [],
        school: {},
        member: {},
        timetable: false,
        loginLinks: [],
        defaultLoginButtonDisabled: false
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getLoginLinks()
        if (this.token.length !== 0) {
            this.getApps()
            this.getTimetable()
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    getTimetable() {
        axios.get(Constants.APIUrl + '/smartlogin/apps/timetable',
            {
                headers: {
                    'Authorization': "Bearer " + this.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',

                }
            })
            .then(response => {
                const timetableKeys = Object.keys(response.data.timetable);
                const processedTimetableReponse = [];
                for (const key of timetableKeys) {
                    const tmpResponse = [];
                    const lessonKeys = Object.keys(response.data.timetable[key]['lessons']);
                    let tmpLessons = [];
                    for (const lessonKey of lessonKeys) {
                        tmpLessons.push(response.data.timetable[key]['lessons'][lessonKey]);
                    }
                    response.data.timetable[key].processed_lessons = tmpLessons;
                    tmpResponse.push(response.data.timetable[key]);
                    processedTimetableReponse.push(tmpResponse);
                }
                this.setState({
                    timetable: processedTimetableReponse
                })
            })
            .catch((error) => {
                this.setState({
                    timetable: false
                })
            });
    }

    getApps() {
        axios.get(Constants.APIUrl + '/smartlogin/apps?school=' + this.schoolId,
            {
                headers: {
                    'Authorization': "Bearer " + this.token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .then(response => {
                this.setState({
                    apps: response.data.apps,
                    starredApps: response.data.starred,
                    school: response.data.school,
                    member: response.data.member
                })
            })
            .catch((error) => {
                console.log('error 3 ' + error);
            });
    }

    getLoginLinks() {

        axios.get(Constants.APIUrl + '/smartlogin/apps/loginlinks?school=' + this.schoolId,
            {
                headers: {
                    'Authorization': "Bearer " + this.token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .then(response => {
                this.setState({
                    loginLinks: response.data.links
                });
                if (response.data.defaultLoginButtonDisabled === 1 || response.data.defaultLoginButtonDisabled === '1') {
                    this.setState({
                        defaultLoginButtonDisabled: true
                    });
                }
            })
            .catch((error) => {
                console.log('error 3 ' + error);
            });
    }


    render() {
        return (
            <div>
                {(this.token.length === 0 && this.loginButton === 'true') &&
                <div id="login-bar">
                    {this.state.loginLinks.map((link, i) =>
                        <a className="login-button" alt="login button" key={i}
                           href={link.url} target="_blank" rel="noopener noreferrer" alt="link">
                            {link.text}
                        </a>
                    )}
                    {(this.state.defaultLoginButtonDisabled === false) &&
                    <a className="login-button" alt="login button"
                       href={window.location.origin + "/wp-login.php" + (this.schoolId != 'false' ? '?school=' + this.schoolId : '')}>Login
                    </a>
                    }
                </div>
                }
                {(this.token.length !== 0) &&
                <div id="smartpanel">
                    <div className="wrapper">
                        <div className="cpanel__logo">
                            {(typeof (this.state.school) !== 'undefined') &&
                            <img width="35px" height="35px"
                                 src={(typeof (this.state.school.sc_logo) !== "undefined" && this.state.school.sc_logo != null) ?
                                     "https://cdn.realsmart.co.uk/sc_logos/" + this.state.school.sc_logo :
                                     "https://cdn.realsmart.co.uk/new-cpanel/school-logo-placeholder.png"
                                 }
                                 alt="realsmart logo"/>
                            }
                        </div>
                        <div className="main-apps--mobile">
                            {(typeof (this.state.member) !== 'undefined' && ['admin', 'mentor', 'governor'].indexOf(this.state.member.type) !== -1) &&
                            <WordpressMobile school={this.state.school}/>
                            }
                        </div>
                        <div className="main-apps">
                            <ul className="main-apps-list">
                                {(typeof (this.state.member) !== 'undefined' && ['admin', 'mentor', 'governor'].indexOf(this.state.member.type) !== -1) &&
                                <WordpressDropdown school={this.state.school}/>
                                }
                                {this.state.starredApps.map((app, i) =>
                                    <li className="main-apps__app-icon cpanel-tooltip" key={i}>
                                        <a href={app.link} target="_blank" rel="noopener noreferrer" alt="app"
                                           className="app-icon-link">
                                            <img alt="Google Mail Icon" src={app.image}
                                                 className="app-icon app-icon-gmail"/>
                                            {(app.badge != 0) && <span className="mail-badge">{app.badge}</span>}
                                            <span className="app-name">{app.title}</span>
                                            <span className="tooltiptext">{app.title}</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="cpanel__other-apps">
                            <ul className="other-apps">
                                <AppDropdown starredApps={this.state.starredApps} apps={this.state.apps}/>
                                {(this.state.timetable !== false) &&
                                <TimetableDropdown timetableItems={this.state.timetable}/>
                                }
                                <li className="other-apps__app-icon app-icon--user">
                                    {(!this.state.member.pic) &&
                                    <a href={Constants.AdminURL + "/admin#!/profile"} target="_blank"
                                       rel="noopener noreferrer" alt="app"
                                       className="cpanel-tooltip">
                                        <img alt="Profile Icon"
                                             src="https://cdn.realsmart.co.uk/new-cpanel/cpanel-user-icon.svg"
                                             className="app-icon app-icon-wordpress"/>
                                        < span className="tooltiptext">Profile</span>
                                    </a>
                                    }
                                    {(this.state.member.pic) &&
                                    <a href={Constants.AdminURL + "/admin#!/profile"} target="_blank"
                                       rel="noopener noreferrer" alt="app"
                                       className="cpanel-tooltip">
                                        <img alt="Profile"
                                             src={"https://cdn.realsmart.co.uk/st_pictures/" + this.state.member.pic}
                                             className="app-icon app-icon-wordpress"/>
                                        < span className="tooltiptext">Profile</span>
                                    </a>
                                    }
                                </li>
                                {this.state.member.type == 'admin' &&
                                <li className="other-apps__app-icon app-icon--user">
                                    <a href={Constants.AdminURL + "/admin#!"} target="_blank" rel="noopener noreferrer"
                                       alt="app"
                                       className="cpanel-tooltip">
                                        <img alt="smartAdmin Icon"
                                             src="https://cdn.realsmart.co.uk/new-cpanel/cpanel-smartadmin-cog-icon.svg"
                                             className="app-icon app-icon-wordpress"/>
                                        <span className="tooltiptext">Smart Admin</span>
                                    </a>
                                </li>
                                }
                                <li className="other-apps__app-icon app-icon--settings">
                                    {(this.schoolId == 'false') &&
                                    <a href={Constants.LoginUrl + "/smartlogin/logout"}
                                       alt="app"
                                       className="cpanel-tooltip">
                                        <img alt="Logout Icon"
                                             src="https://cdn.realsmart.co.uk/new-cpanel/cpanel-logout-icon.svg"
                                             className="app-icon app-icon-wordpress"/>
                                    </a>
                                    }
                                    {(this.schoolId != 'false' && this.state.school.sc_wordpress_url != '') &&
                                    <a href={this.state.school.sc_wordpress_url + "/wp-login.php?action=logout"}
                                       alt="app"
                                       className="cpanel-tooltip">
                                        <img alt="Logout icon"
                                             src="https://cdn.realsmart.co.uk/new-cpanel/cpanel-logout-icon.svg"
                                             className="app-icon app-icon-wordpress"/>
                                    </a>
                                    }
                                    {(this.schoolId != 'false' && this.state.school.sc_wordpress_url == '') &&
                                    <a href={Constants.LoginUrl + "/smartlogin/logout"}
                                       alt="app"
                                       className="cpanel-tooltip">
                                        <img alt="Logout icon"
                                             src="https://cdn.realsmart.co.uk/new-cpanel/cpanel-logout-icon.svg"
                                             className="app-icon app-icon-wordpress"/>
                                    </a>
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                    {(typeof (this.state.school.google_domain) !== 'undefined') &&
                    <iframe
                        src={"https://docs.google.com/a/" + this.state.school.google_domain + "/forms/d/e/1FAIpQLScmVjxopHg-rdAZSaYu9sve90sNKpQU4mDmwUJUjGjIzHRphA/viewform?embedded=true"}
                        width="0px" height="0px" frameBorder="0" marginHeight="0" marginWidth="0" title="Force Login">
                    </iframe>
                    }
                </div>
                }
            </div>
        );
    }
}

export default App;
